import { Button, Row, Space } from "antd";

import { OtherPaymentType } from "~/adapters";
import { PaymentOverviewCard } from "~/components";
import { routePathByPage, Permission } from "~/constants";
import { useCheckPermissions } from "~/hooks";

import { usePaymentDetails } from "../hooks";
import { PaymentHeader } from "../PaymentHeader";
import { PaymentType } from "../types";

import { useMarkAsUnidentified } from "./useMarkAsUnidentified";

const allowedForMarkAsUnidentifiedCategories = [
  OtherPaymentType.Refund,
  OtherPaymentType.RatenkaufFinancing,
  OtherPaymentType.OnHold,
  OtherPaymentType.FinancingKickback,
  OtherPaymentType.Internal,
  OtherPaymentType.NotApplicable,
  OtherPaymentType.PenaltyFees,
];

export function InternalPaymentView() {
  const { data: payment } = usePaymentDetails(PaymentType.Internal);
  const hasMarkAsUnidentifiedPermission = useCheckPermissions(
    Permission.markInternalAsUnidentifiedPayment
  );
  const { isLoading: isMarking, mutate: markAsUnidentified } =
    useMarkAsUnidentified();

  return payment ? (
    <>
      <PaymentHeader
        payment={payment}
        navigationPath={routePathByPage.internalView}
      />
      <PaymentOverviewCard mode="view" payment={payment} />
      <Row justify="end" style={{ marginTop: 16 }}>
        <Space>
          {hasMarkAsUnidentifiedPermission &&
            allowedForMarkAsUnidentifiedCategories.includes(
              payment.category
            ) && (
              <Button
                type="primary"
                loading={isMarking}
                onClick={() => markAsUnidentified(payment.id)}
              >
                Mark as unidentified
              </Button>
            )}
        </Space>
      </Row>
    </>
  ) : null;
}
