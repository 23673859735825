export const validateOrderNumbers = (
  existingOrderNumbers: string[],
  orderNumbersToCheck: string[]
) => {
  const unknownOrderNumbers = orderNumbersToCheck.filter(
    (pastedOrderNumber) => !existingOrderNumbers?.includes(pastedOrderNumber)
  );

  if (unknownOrderNumbers.length) {
    return `Order number${
      unknownOrderNumbers.length > 1 ? "s" : ""
    } "${unknownOrderNumbers.join('", "')}" ${
      unknownOrderNumbers.length > 1 ? "do" : "does"
    } not exist`;
  }
};
