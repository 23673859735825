export type PaymentDistribution = {
  orderNumberOrCategory: string;
  amountMinorUnits: number;
  unknownFields?: unknown[];
};

const parseAmountAsMinorUnits = (amountInput: string): number | undefined => {
  const amountString = amountInput.replace(",", ".").replace(/\s/gim, "");
  const matchResult = /(\d+)(\.(\d{2}))?/.exec(amountString);
  if (matchResult) {
    const [_, integralPart, __, fractionalPart] = matchResult;
    const minorUnitsAsString = `${integralPart}${fractionalPart ?? "00"}`;
    return Number(minorUnitsAsString);
  }
};

export const parsePaymentDistributionInput = (
  input: string,
  rowSeparator = /\n/,
  columnSeparator = ";"
): Array<PaymentDistribution> =>
  input
    .split(rowSeparator)
    .filter((row) => row)
    .map((row) => {
      const [orderNumberOrCategory, amount, ...rest] = row
        .trim()
        .split(columnSeparator)
        .map((column) => column.trim());

      const parsedAmount = parseAmountAsMinorUnits(amount);

      return {
        orderNumberOrCategory,
        amountMinorUnits: parsedAmount && parsedAmount > 0 ? parsedAmount : 0,
        unknownFields: rest,
      };
    });
