import { FetchOtherPaymentsResponse } from "../../fetchPayments";
import { PaymentCsvColumn } from "../types";
import { formatDate, formatPaymentMethod, getAmount } from "../../../../utils";

export const otherPaymentsResponseToCsvRowsAdapter = (
  response: FetchOtherPaymentsResponse
) =>
  response.searchInternalTransferPayments.entities.map((entity) => ({
    [PaymentCsvColumn.BankAccount]: "-",
    [PaymentCsvColumn.AccountingDate]: formatDate(entity.accountingDate),
    [PaymentCsvColumn.PaymentDate]: "-",
    [PaymentCsvColumn.BankReferenceNumber]: entity.externalId,
    [PaymentCsvColumn.EndToEndReference]: "-",
    [PaymentCsvColumn.PaymentMethod]: formatPaymentMethod(entity.type),
    [PaymentCsvColumn.Gvc]: "-",
    [PaymentCsvColumn.Amount]: entity.amount?.amountMinorUnits
      ? getAmount(entity.amount.amountMinorUnits, true)
      : "-",
    [PaymentCsvColumn.CurrencyCode]: entity.amount?.currencyCode ?? "-",
    [PaymentCsvColumn.AccountHolderName]: entity.accountData?.holder ?? "-",
    [PaymentCsvColumn.CustomerPaymentReference]: "-",
    [PaymentCsvColumn.Bic]: entity.accountData?.bic ?? "-",
    [PaymentCsvColumn.Iban]: entity.accountData?.iban ?? "-",
    id: entity.id,
  }));
