export enum CardLabels {
  createdOn = "Created On (Upload Timestamp)",
  accountingDate = "Accounting date",
  source = "Creation source",
  recipientBank = "Recipient bank account",
  paymentType = "Type of payment (payment method)",
  uploadStatus = "Upload status",
  errorMessage = "Uploading errors + timestamp",
  amount = "Amount (Currency)",
  accountHolderName = "Account holder name",
  customerName = "Customer Name (from Order)",
  bic = "BIC",
  iban = "IBAN",
  customerReference = "Customer payment reference",
  orderId = "Order Number",
  stockId = "Stock ID",
  retailCountry = "Retail Country",
  category = "Category",
}
