import { stringify } from "query-string";
import { useCallback } from "react";
import { generatePath, useHistory } from "react-router";
import { useParams } from "react-router-dom";

import { PaymentType } from "./types";
import { PaymentOverviewEntity } from "./PaymentsOverview/hooks";
import { InboundsFilter } from "./PaymentsOverview/PaymentsOverviewFilter";

export const useNavigateToPaymentDetails = (path: string) => {
  const history = useHistory<{ filter: InboundsFilter; page: number }>();
  const { lang, paymentType } = useParams<{
    lang: string;
    paymentType: PaymentType;
  }>();
  return useCallback(
    (payment: PaymentOverviewEntity, page: number) => {
      const generatedPath = generatePath(path, {
        lang,
        paymentType,
        id: payment.id,
      });
      const orderId = payment.orderId;
      const externalId = payment.externalId;
      const params = stringify({ page, orderId, externalId });
      history.push(`${generatedPath}?${params}`, {
        ...history.location.state,
        page,
      });
    },
    [history, lang, path, paymentType]
  );
};
