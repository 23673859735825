import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

interface TotalResultsProps {
  totalCount: number;
}

const TotalResults = ({ totalCount }: TotalResultsProps) => (
  <Title level={4}>{totalCount} total results</Title>
);

export default TotalResults;
