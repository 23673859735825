import { Button, Form, Input, Typography } from "antd";
import { useEffect } from "react";

import { labelByOtherPaymentType } from "../../../constants";

const { Text, Paragraph } = Typography;
export type DistributePaymentFormVM = {
  paymentDistribution: string | undefined;
};

export const DistributePaymentForm = ({
  onSubmit,
  errors,
  isLoading,
}: {
  errors: string[];
  isLoading: boolean;
  onSubmit: (values: DistributePaymentFormVM) => void;
}) => {
  const [form] = Form.useForm<DistributePaymentFormVM>();

  useEffect(() => () => form.resetFields(), [form]);

  return (
    <>
      <div>
        <Text strong>Example:</Text>
        <Paragraph style={{ marginBottom: 0 }} code>
          DE725376236; 1200
        </Paragraph>
        <Paragraph code>{labelByOtherPaymentType.LOGISTIC}; 30000,50</Paragraph>
      </div>
      <Form layout="vertical" form={form} onFinish={onSubmit}>
        <Form.Item
          help={
            <Form.ErrorList
              className="text-red"
              helpStatus="error"
              errors={errors}
            />
          }
          label="Payment distribution:"
          status={errors.length ? "error" : ""}
          name="paymentDistribution"
        >
          <Input.TextArea
            status={errors.length ? "error" : ""}
            name="paymentDistribution"
          />
        </Form.Item>
        <Button
          type="primary"
          onClick={form.submit}
          style={{ marginBottom: 24, marginTop: 12 }}
          htmlType="submit"
          loading={isLoading}
        >
          Process
        </Button>
      </Form>
    </>
  );
};
