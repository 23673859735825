import { FetchSuccessfulPaymentsResponse } from "../../fetchPayments";
import { PaymentCsvColumn } from "../types";
import { formatDate, formatPaymentMethod, getAmount } from "../../../../utils";

export const successfulPaymentsResponseToCsvRowsAdapter = (
  response: FetchSuccessfulPaymentsResponse
) =>
  response.searchRetailPayments.entities.map((entity) => ({
    [PaymentCsvColumn.BankAccount]:
      entity.importDetails?.recipientBankAccount ?? "-",
    [PaymentCsvColumn.AccountingDate]: formatDate(entity.accountingDate),
    [PaymentCsvColumn.PaymentDate]: entity.paymentDate
      ? formatDate(entity.paymentDate)
      : "-",
    [PaymentCsvColumn.BankReferenceNumber]: entity.externalId,
    [PaymentCsvColumn.EndToEndReference]: entity.orderNumber,
    [PaymentCsvColumn.PaymentMethod]: formatPaymentMethod(entity.type),
    [PaymentCsvColumn.Gvc]: entity.importDetails?.transactionCode ?? "-",
    [PaymentCsvColumn.Amount]: entity.amount?.amountMinorUnits
      ? getAmount(entity.amount.amountMinorUnits, true)
      : "-",
    [PaymentCsvColumn.CurrencyCode]: entity.amount?.currencyCode ?? "-",
    [PaymentCsvColumn.AccountHolderName]: entity.accountDetails?.holder ?? "-",
    [PaymentCsvColumn.CustomerPaymentReference]: entity.orderNumber,
    [PaymentCsvColumn.Bic]: entity.accountDetails?.bic ?? "-",
    [PaymentCsvColumn.Iban]: entity.accountDetails?.iban ?? "-",
    id: entity.id,
  }));
