export const transitionPaymentUnidentifiedToInternalTransferBatch = /* GraphQL */ `
  mutation (
    $request: BatchProjection_RetailTransitionInternalTransferDTOInput!
  ) {
    transitionPaymentUnidentifiedToInternalTransferBatch(request: $request) {
      entities {
        id
      }
    }
  }
`;
