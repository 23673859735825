import { stringify } from "query-string";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router";

import { useCurrentPaymentType } from "./hooks";
import { InboundsFilter } from "./PaymentsOverview/PaymentsOverviewFilter";

export const useGoBackToOverviewPage = () => {
  const history = useHistory<{ filter: InboundsFilter; page: number }>();
  const paymentType = useCurrentPaymentType();
  const { lang } = useParams<{ lang?: string }>();

  return useCallback(
    () =>
      history.push(
        `/${lang}/payments/${paymentType}${
          history.location.state
            ? `?${stringify({
                ...history.location.state.filter,
                page: history.location.state.page,
              })}`
            : ""
        }`
      ),
    [history, lang, paymentType]
  );
};
