import { useCallback, useContext } from "react";

import { Permission } from "../constants";
import { PermissionsContext } from "../providers/PermissionsProvider";

export const useHasPermissions = () => {
  const { permissions } = useContext(PermissionsContext);

  return useCallback(
    (types: Permission | Permission[]) =>
      [types].flat().some((type) => permissions.includes(type)),
    [permissions]
  );
};
