import React, { useState } from "react";
import { generatePath, Link, useLocation } from "react-router-dom";
import { Menu, MenuProps } from "antd";

import { routePathByPage } from "../../../constants";
import { PaymentType } from "../../../pages/types";

const { Item } = Menu;

const PaymentsOverviewNavMenu = () => {
  const { pathname } = useLocation();
  const lang = pathname.slice(1, 3);
  const [current, setCurrent] = useState<string>(pathname.slice(3));
  const onClick: MenuProps["onClick"] = ({ key }) => setCurrent(key);

  return (
    <Menu mode="horizontal" selectedKeys={[current]} onClick={onClick}>
      <Item key={`/payments/${PaymentType.Successful}`}>
        <Link
          to={generatePath(routePathByPage.list, {
            lang,
            paymentType: PaymentType.Successful,
          })}
          data-qa-selector="successfulTab"
          style={{ textDecoration: "none" }}
        >
          Successful
        </Link>
      </Item>
      <Item key={`/payments/${PaymentType.Internal}`}>
        <Link
          to={generatePath(routePathByPage.list, {
            lang,
            paymentType: PaymentType.Internal,
          })}
          data-qa-selector="internalTab"
          style={{ textDecoration: "none" }}
        >
          Other
        </Link>
      </Item>
      <Item key={`/payments/${PaymentType.Unidentified}`}>
        <Link
          to={generatePath(routePathByPage.list, {
            lang,
            paymentType: PaymentType.Unidentified,
          })}
          data-qa-selector="unidentifiedTab"
          style={{ textDecoration: "none" }}
        >
          Unidentified
        </Link>
      </Item>
    </Menu>
  );
};

export default PaymentsOverviewNavMenu;
