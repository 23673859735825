export const successfulPaymentQuery = `query($paymentId: UUID!, $orderId: UUID!) {
  retailPayment: retailPayment(id: $paymentId) {
    id
    importDetails {
      recipientBankAccount
      customerPaymentReference
    }
    accountDetails {
      bic
      holder
      iban
    }
    accountingDate
    amount {
      amountMinorUnits
      currencyCode
    }
    countryCode
    createdOn
    creationSource
    externalPaymentSystem
    externalId
    orderId
    type
  },
  order: order(id: $orderId) {
    orderNumber
    stockNumber
    customer {
      firstName
      lastName
    }
  }
}`;
