import { PaymentMethod } from "../pages/types";

export const unidentifiedPaymentMethodsMap = {
  WIRE_TRANSFER_CUSTOMER: "Wire transfer - Customer",
  WIRE_TRANSFER_BANK_FINANCE: "Wire transfer - Bank finance",
};

export const methodsMap = {
  ONLINE_CREDIT_CARD: "Online - Credit card",
  ONLINE_BANK_TRANSFER: "Online - Bank transfer",
  ONLINE_PAYPAL: "Online - Paypal",
  ONLINE_DEBIT_CARD: "Online - Debit Card",
  MANUALLY_CREATED: "Manually created",
  ...unidentifiedPaymentMethodsMap,
  TRADE_IN: "Trade-in",
};

export const formatPaymentMethod = (paymentMethod: PaymentMethod) =>
  Object.prototype.hasOwnProperty.call(methodsMap, paymentMethod)
    ? methodsMap[paymentMethod]
    : paymentMethod;
