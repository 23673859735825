import { CreationSource } from "../adapters/formatSource";
import { OtherPaymentType } from "../adapters";
import {
  methodsMap,
  unidentifiedPaymentMethodsMap,
} from "../utils/paymentMethod";

export const DEFAULT_PAGE = 1;
export const PAGE_SIZE = 50;

export const ERROR_MESSAGE = "Oops! Something went wrong.";

export enum PaymentUploadStatus {
  SUCCESSFUL = "Successful",
  UNIDENTIFIED = "Unidentified",
}

export const paymentMethodsOptions = Object.entries(methodsMap).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const creationSourceOptions = Object.entries(CreationSource).map(
  ([value, label]) => ({
    value,
    label,
  })
);

export const unidentifiedPaymentMethodsOptions = Object.entries(
  unidentifiedPaymentMethodsMap
).map(([value, label]) => ({
  value,
  label,
}));

export const labelByOtherPaymentType: Record<OtherPaymentType, string> = {
  [OtherPaymentType.Chargeback]: "Chargeback",
  [OtherPaymentType.ChequePaymentsBatch]: "Cheque Payments Batch",
  [OtherPaymentType.DuplicateFinancingAmount]: "Duplicate Financing Amount",
  [OtherPaymentType.FinancingKickback]: "Financing Kickback",
  [OtherPaymentType.Internal]: "Internal",
  [OtherPaymentType.Legal]: "Legal",
  [OtherPaymentType.Logistic]: "Logistic",
  [OtherPaymentType.NotApplicable]: "Not applicable payment",
  [OtherPaymentType.OnHold]: "On hold",
  [OtherPaymentType.OutgoingRefund]: "Outgoing Refund",
  [OtherPaymentType.Overpayment]: "Overpayment",
  [OtherPaymentType.PenaltyFees]: "Penalty Fees",
  [OtherPaymentType.RatenkaufFinancing]: "Ratenkauf financing",
  [OtherPaymentType.Refund]: "Refund",
  [OtherPaymentType.RejectedOutbound]: "Rejected outbound payment",
  [OtherPaymentType.Test]: "Test",
};

export const otherPaymentTypeByLabel = (
  Object.keys(labelByOtherPaymentType) as OtherPaymentType[]
).reduce<Record<string, OtherPaymentType>>((acc, otherPaymentType) => {
  acc[labelByOtherPaymentType[otherPaymentType].toLowerCase()] =
    otherPaymentType;
  return acc;
}, {});
