import { useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";

import { useQueryParams } from "../../../hooks";

import { useChangeRetailPaymentOrder } from "./useChangeRetailPaymentOrder";

export const ChangeOrder = () => {
  const [opened, setOpened] = useState(false);

  const handleCancel = () => setOpened(false);

  const { data, isLoading, mutate } = useChangeRetailPaymentOrder({
    onSuccess: handleCancel,
  });
  const [form] = Form.useForm();
  const [queryParams, setQueryParams] = useQueryParams();
  const newOrderId = data?.changeRetailPaymentOrder?.orderId;
  useEffect(() => {
    if (newOrderId) {
      setQueryParams({ ...queryParams, orderId: newOrderId });
    }
  }, [newOrderId, queryParams, setQueryParams]);

  return (
    <>
      <Button type={"primary"} onClick={() => setOpened(true)}>
        Change order
      </Button>

      <Modal
        title="Change Order"
        visible={opened}
        onCancel={handleCancel}
        okButtonProps={{ loading: isLoading }}
        onOk={() => {
          mutate(form.getFieldValue("orderNumber"));
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
        >
          <Form.Item
            name="orderNumber"
            label="Order Number"
            rules={[
              {
                required: true,
                message: "Please input the title of collection!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
