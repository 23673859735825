import { PageHeader } from "antd";

import { Path } from "../constants";

import { PaymentDetailsNavigation } from "./PaymentDetailsNavigation";
import { PaymentOverviewEntity } from "./PaymentsOverview/hooks";
import { useNavigateToPaymentDetails } from "./useNavigateToPaymentDetails";
import { useGoBackToOverviewPage } from "./useGoBackToOverviewPage";

type PaymentHeaderProps = {
  payment?: Pick<PaymentOverviewEntity, "externalId">;
  navigationPath: Path;
};

export const PaymentHeader = ({
  payment,
  navigationPath,
}: PaymentHeaderProps) => {
  const navigateToPayment = useNavigateToPaymentDetails(navigationPath);
  const goBackToOverviewPage = useGoBackToOverviewPage();

  return (
    <PageHeader
      onBack={goBackToOverviewPage}
      title={`Payment #${payment?.externalId ?? ""}`}
      extra={
        <PaymentDetailsNavigation
          onSearchMoreClick={goBackToOverviewPage}
          onNavigate={navigateToPayment}
        />
      }
    />
  );
};
