import { Button } from "antd";

import { useHasPermissions } from "../../hooks";
import { UnidentifiedEditPermission } from "../../constants";

export const renderCell = (qaSelector: string) => (props: string) =>
  <span data-qa-selector={qaSelector}>{props}</span>;

type OrderMatchesActionsColumnProps = {
  onClick: () => void;
  qaSelector: string;
  btnQaSelector: string;
};

export const OrderMatchesActionsColumn = ({
  qaSelector,
  btnQaSelector,
  onClick,
}: OrderMatchesActionsColumnProps) => {
  const hasPermission = useHasPermissions();
  return (
    <div data-qa-selector={qaSelector}>
      {hasPermission(
        UnidentifiedEditPermission.assignUnidentifiedPaymentToOrder
      ) && (
        <Button
          type="primary"
          onClick={onClick}
          data-qa-selector={btnQaSelector}
        >
          Attach
        </Button>
      )}
    </div>
  );
};
