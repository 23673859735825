import { callGql } from "../../../../utils/apiClient";
import { Domain, PAGE_SIZE } from "../../../../constants";

import { orderNumbersQuery, OrderNumbersQueryData } from "./orderNumbersQuery";

export const fetchOrderNumbers = async (
  orderIds: string[]
): Promise<Record<string, string>> => {
  if (orderIds.length === 0) return {};

  const variables = {
    search: {
      filter: {
        field: "id",
        op: "in",
        value: orderIds,
      },
      sorts: [{ property: "created", direction: "DESC" }],
      page: 0,
      pageSize: PAGE_SIZE,
    },
  };

  const { data, errors } = await callGql<OrderNumbersQueryData>({
    query: orderNumbersQuery,
    variables,
    domain: Domain.ORDER,
  });

  if (errors?.length) {
    throw errors[0].exception?.localizedMessage;
  }

  // TODO
  return (
    data?.searchOrders?.entities?.reduce(
      (acc: Record<string, string>, { id, orderNumber }) => {
        acc[id] = orderNumber;

        return acc;
      },
      {} as Record<string, string>
    ) ?? {}
  );
};
