import { ReactNode } from "react";
import { Card, Row, Col, Form, Select, Input } from "antd";

import {
  paymentOverviewSelectors,
  unidentifiedPaymentMethodsOptions,
  PaymentUploadStatus,
  labelByOtherPaymentType,
  UnidentifiedEditPermission,
} from "../../constants";
import { Payment } from "../../pages/types";
import { hasOrderDetails } from "../../adapters/types";
import { formatPaymentMethod } from "../../utils";
import { useHasPermissions } from "../../hooks";

import { CardLabels } from "./config";
import { ChangeOrder } from "./ChangeOrder";

interface PaymentOverviewCardRowProps {
  label: string;
  value: string | ReactNode;
  qaSelector: string;
}

const PaymentOverviewCardRow = ({
  label,
  value,
  qaSelector,
}: PaymentOverviewCardRowProps) => (
  <Row style={{ marginBottom: "5px" }}>
    <Col span={8}>{label}</Col>
    <Col span={16}>
      <span data-qa-selector={qaSelector} style={{ wordBreak: "break-word" }}>
        {value}
      </span>
    </Col>
  </Row>
);

const renderError = (error: string, timestamp: string) => (
  <>
    <span style={{ color: "red" }}>{error}</span> {timestamp}
  </>
);

const renderUploadStatus = (status: string) => {
  const style = {
    color: status === PaymentUploadStatus.SUCCESSFUL ? "green" : "red",
  };

  return <span style={style}>{status}</span>;
};

const headStyle = { backgroundColor: "#f5f5f5", borderColor: "#ddd" };

export type UpdatePaymentVM = Partial<
  Pick<
    Payment,
    | "paymentType"
    | "customerReference"
    | "accountHolderName"
    | "bic"
    | "iban"
    | "status"
  >
>;

export type PaymentField = keyof UpdatePaymentVM;

type PaymentOverviewCardProps = {
  payment?: Payment;
  mode: "edit" | "view";
  editableFields?: PaymentField[];
};

const PaymentOverviewCard = (props: PaymentOverviewCardProps) => {
  const { payment, mode, editableFields } = props;
  const hasPermission = useHasPermissions();

  if (!payment) return null;

  const {
    createdOn,
    accountingDate,
    source,
    recipientBank,
    paymentType,
    errorMessage,
    amount,
    accountHolderName,
    bic,
    iban,
    customerReference,
    retailCountry,
  } = payment;

  return (
    <Row gutter={[5, 5]} align="stretch">
      <Col span={12}>
        <Card
          title="General details"
          style={{ height: "100%" }}
          headStyle={headStyle}
        >
          <PaymentOverviewCardRow
            label={CardLabels.retailCountry}
            value={retailCountry}
            qaSelector={paymentOverviewSelectors.retailCountry}
          />
          <PaymentOverviewCardRow
            label={CardLabels.createdOn}
            value={createdOn}
            qaSelector={paymentOverviewSelectors.createdOn}
          />
          <PaymentOverviewCardRow
            label={CardLabels.source}
            value={source}
            qaSelector={paymentOverviewSelectors.source}
          />
          {"uploadStatus" in payment && (
            <PaymentOverviewCardRow
              label={CardLabels.uploadStatus}
              value={renderUploadStatus(payment.uploadStatus)}
              qaSelector={paymentOverviewSelectors.uploadStatus}
            />
          )}
          {"category" in payment && (
            <PaymentOverviewCardRow
              label={CardLabels.category}
              value={labelByOtherPaymentType[payment.category]}
              qaSelector={paymentOverviewSelectors.category}
            />
          )}
          <PaymentOverviewCardRow
            label={CardLabels.paymentType}
            value={
              mode === "edit" && editableFields?.includes("paymentType") ? (
                <Form.Item name="paymentType" style={{ marginBottom: 0 }}>
                  <Select
                    size="middle"
                    placeholder="Payment method"
                    options={unidentifiedPaymentMethodsOptions}
                  />
                </Form.Item>
              ) : (
                formatPaymentMethod(paymentType)
              )
            }
            qaSelector={paymentOverviewSelectors.paymentType}
          />
          <PaymentOverviewCardRow
            label={CardLabels.accountingDate}
            value={accountingDate}
            qaSelector={paymentOverviewSelectors.accountingDate}
          />
          <PaymentOverviewCardRow
            label={CardLabels.amount}
            value={amount}
            qaSelector={paymentOverviewSelectors.amount}
          />
          <PaymentOverviewCardRow
            label={CardLabels.recipientBank}
            value={recipientBank}
            qaSelector={paymentOverviewSelectors.recipientBank}
          />
          <PaymentOverviewCardRow
            label={CardLabels.customerReference}
            value={
              mode === "edit" &&
              editableFields?.includes("customerReference") ? (
                <Form.Item name="customerReference" style={{ marginBottom: 0 }}>
                  <Input
                    size="middle"
                    placeholder="Customer reference"
                    name="customerReference"
                  />
                </Form.Item>
              ) : (
                customerReference
              )
            }
            qaSelector={paymentOverviewSelectors.customerReference}
          />
          {errorMessage && (
            <PaymentOverviewCardRow
              label={CardLabels.errorMessage}
              value={renderError(errorMessage, createdOn)}
              qaSelector={paymentOverviewSelectors.errorMessage}
            />
          )}
        </Card>
      </Col>
      <Col span={12}>
        <Row gutter={[5, 5]}>
          <Col span={24}>
            <Card title="Bank account details" headStyle={headStyle}>
              <PaymentOverviewCardRow
                label={CardLabels.accountHolderName}
                value={
                  mode === "edit" &&
                  editableFields?.includes("accountHolderName") ? (
                    <Form.Item
                      name="accountHolderName"
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        size="middle"
                        placeholder="Account holder name"
                        name="accountHolderName"
                      />
                    </Form.Item>
                  ) : (
                    accountHolderName
                  )
                }
                qaSelector={paymentOverviewSelectors.accountHolderName}
              />
              <PaymentOverviewCardRow
                label={CardLabels.bic}
                value={
                  mode === "edit" && editableFields?.includes("bic") ? (
                    <Form.Item name="bic" style={{ marginBottom: 0 }}>
                      <Input size="middle" placeholder="BIC" name="bic" />
                    </Form.Item>
                  ) : (
                    bic
                  )
                }
                qaSelector={paymentOverviewSelectors.bic}
              />
              <PaymentOverviewCardRow
                label={CardLabels.iban}
                value={
                  mode === "edit" && editableFields?.includes("iban") ? (
                    <Form.Item name="iban" style={{ marginBottom: 0 }}>
                      <Input size="middle" placeholder="IBAN" name="iban" />
                    </Form.Item>
                  ) : (
                    iban
                  )
                }
                qaSelector={paymentOverviewSelectors.iban}
              />
            </Card>
          </Col>
          {hasOrderDetails(payment) && (
            <Col span={24}>
              <Card
                title="Order details"
                headStyle={headStyle}
                extra={
                  hasPermission(
                    UnidentifiedEditPermission.changeRetailPaymentOrder
                  ) && <ChangeOrder />
                }
              >
                <PaymentOverviewCardRow
                  label={CardLabels.customerName}
                  value={payment.customerName}
                  qaSelector={paymentOverviewSelectors.customerName}
                />
                <PaymentOverviewCardRow
                  label={CardLabels.orderId}
                  value={payment.orderId}
                  qaSelector={paymentOverviewSelectors.orderId}
                />
                <PaymentOverviewCardRow
                  label={CardLabels.stockId}
                  value={payment.stockId}
                  qaSelector={paymentOverviewSelectors.stockId}
                />
              </Card>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default PaymentOverviewCard;
