import { Button } from "antd";
import { useState } from "react";

import { paymentOverviewSelectors } from "../../constants";

import { MarkAsOtherPaymentModal } from "./MarkAsOtherPaymentModal";

export const MarkAsOtherPaymentButton = ({
  paymentId,
  orderId,
}: {
  paymentId: string;
  orderId: string;
}) => {
  const [showModal, setShowModal] = useState(false);
  const open = () => setShowModal(true);
  const close = () => setShowModal(false);

  return (
    <>
      <Button
        type="primary"
        onClick={open}
        data-qa-selector={paymentOverviewSelectors.markAsInternalBtn}
      >
        Mark as other payment
      </Button>
      <MarkAsOtherPaymentModal
        orderId={orderId}
        isVisible={showModal}
        paymentId={paymentId}
        onCancel={close}
      />
    </>
  );
};
