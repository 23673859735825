import { routePathByPage } from "../../constants";
import { PaymentOverviewCard } from "../../components";
import { usePaymentDetails } from "../hooks";
import { PaymentHeader } from "../PaymentHeader";
import { PaymentType } from "../types";

export function UnidentifiedPaymentView() {
  const { data: payment } = usePaymentDetails(PaymentType.Unidentified);

  return (
    <>
      <PaymentHeader
        payment={payment}
        navigationPath={routePathByPage.unidentifiedView}
      />
      <PaymentOverviewCard mode="view" payment={payment} />
    </>
  );
}
