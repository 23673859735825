import React, { useState, useCallback, FC } from "react";

export const ErrorContext = React.createContext({
  error: null as string | null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addError: (_message: string | null) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  removeError: () => {},
});

export const ErrorProvider: FC = ({ children }) => {
  const [error, setError] = useState<string | null>(null);
  const removeError = () => setError(null);
  const addError = (message: string) => setError(message);

  const contextValue = {
    error,
    addError: useCallback((message) => addError(message), []),
    removeError: useCallback(() => removeError(), []),
  };

  return (
    <ErrorContext.Provider value={contextValue}>
      {children}
    </ErrorContext.Provider>
  );
};
