import { ExportToCsv, Options } from "export-to-csv";

const defaultOptions: Options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  useTextFile: false,
  useKeysAsHeaders: true,
};

export const saveAsCsv = <T>(data: T[], options: Partial<Options> = {}) =>
  new ExportToCsv({ ...defaultOptions, ...options }).generateCsv(data);
