export enum CreationSource {
  AUTOMATED_BATCH_IMPORT = "Automated batch import",
  MANUAL_BATCH_IMPORTER = "Manual batch importer",
  MANUALLY_CREATED = "Manually created payment",
  EXTERNAL_PAYMENT_SYSTEM = "Adyen",
  DEUTSCHE_BANK_API = "Deutsche bank API",
  INTERNAL_FINANCING_AUTO_CREATION = "Automatically created - Internal Financing",
  CITI_BANK_API = "Citibank API",
}

export const formatSource = ({
  hasExternalPaymentSystem,
  creationSource,
}: {
  hasExternalPaymentSystem: boolean;
  creationSource?: keyof typeof CreationSource;
}) => {
  if (creationSource) {
    return CreationSource[creationSource];
  }

  return hasExternalPaymentSystem
    ? CreationSource.EXTERNAL_PAYMENT_SYSTEM
    : `${CreationSource.MANUAL_BATCH_IMPORTER} / ${CreationSource.MANUALLY_CREATED}`;
};
