import React from "react";
import { Pagination as AntdPagination } from "antd";

interface PaginationProps {
  totalCount: number;
  page: number;
  pageSize: number;
  onChange: (page: number) => void;
}

const Pagination = ({
  totalCount,
  page,
  pageSize,
  onChange,
}: PaginationProps) => (
  <AntdPagination
    total={totalCount}
    defaultPageSize={pageSize}
    current={page}
    showSizeChanger={false}
    onChange={onChange}
  />
);

export default Pagination;
