export const orderNumbersQuery = `query($search: SearchProjection2Input!) {
  searchOrders(search: $search) {
    entities {
      id
      orderNumber
    }
  }
}`;

export type OrderNumbersQueryData = {
  searchOrders?: {
    entities?: Array<{
      id: string;
      orderNumber: string;
    }>;
  };
};
