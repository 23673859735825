export const unidentifiedPaymentQuery = `query($paymentId: UUID!) {
    unidentifiedPaymentById(id: $paymentId) {
        accountingDate
        errorMessage
        externalId
        id
        status
        paymentData {
          accountHolder
          accountingDate
          amount
          bankAccount
          bankReferenceNumber
          bic
          countryCode
          currencyCode {
            description
          }
          customerPaymentReference
          iban
          orderNumbers
          paymentDate
          paymentType
        }
        uploadDate
        creationSource
    }
}`;
