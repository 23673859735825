import { Domain } from "../../../constants";
import {
  successfulPaymentQuery,
  otherPaymentQuery,
  unidentifiedPaymentQuery,
} from "../../../graphql";
import {
  successfulPaymentAdapter,
  otherPaymentAdapter,
  unidentifiedPaymentAdapter,
} from "../../../adapters";

import { CreationSource } from "./../../../adapters/formatSource";
import { PaymentMethod } from "./../../types";

export const gqlConfig = {
  successful: {
    query: successfulPaymentQuery,
    domain: Domain.ORDER,
    adapter: successfulPaymentAdapter,
  },
  internal: {
    query: otherPaymentQuery,
    domain: Domain.PAYMENTS,
    adapter: otherPaymentAdapter,
  },
  unidentified: {
    query: unidentifiedPaymentQuery,
    domain: Domain.PAYMENTS,
    adapter: unidentifiedPaymentAdapter,
  },
};

export type SuccessfulPaymentResponse = {
  retailPayment: SuccessfulPaymentEntity;
  order: {
    orderNumber: string;
    stockNumber: string;
    customer: {
      firstName: string;
      lastName: string;
    };
  };
};

type SuccessfulPaymentEntity = {
  id: string;
  importDetails?: {
    recipientBankAccount?: string;
    customerPaymentReference?: string;
  };
  accountDetails: {
    bic: string;
    holder: string;
    iban: string;
  };
  accountingDate?: string;
  amount: {
    amountMinorUnits: number;
    currencyCode: string;
  };
  countryCode: string;
  createdOn?: string;
  creationSource?: keyof typeof CreationSource;
  externalPaymentSystem?: unknown;
  externalId: string;
  orderId?: string;
  type: PaymentMethod;
};
