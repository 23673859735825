import React from "react";
import { Alert } from "antd";

import { useError } from "../../hooks";

const ErrorManager = () => {
  const { error, removeError } = useError();

  return (
    <div
      style={{
        position: "fixed",
        top: "110px",
        right: "30px",
        zIndex: 1001,
      }}
    >
      {error && (
        <Alert
          closable
          showIcon
          type="error"
          message={error}
          onClose={removeError}
        />
      )}
    </div>
  );
};

export default ErrorManager;
