export const otherPaymentsQuery = `query($search: SearchProjection2Input!) {
  searchInternalTransferPayments(search: $search) {
    totalEntityCount
    entities {
      accountData {
        holder
        bic
        iban
      }
      accountingDate
      amount {
        amountMinorUnits
        currencyCode
      }
      countryCode
      createdOn
      externalId
      id
      type
    }
  }
}`;
