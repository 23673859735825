const numberWithDots = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
const getWholeNumber = (num: number) => Math.trunc(num);
const getRemainder = (num: number) => {
  const remainder = num - getWholeNumber(num);

  return Number(remainder.toFixed(2));
};
const convertMinorToMajor = (num: number) => num / 100;
export const convertMajorToMinor = (num: number) => num * 100;
const splitAmount = (amount: number, minor: boolean): [number, number] => {
  const majorAmount = minor ? convertMinorToMajor(amount) : amount;
  const wholeNumber = getWholeNumber(majorAmount);
  const remainder = getRemainder(majorAmount);

  return [wholeNumber, remainder];
};

export const getAmount = (amountMinorUnits: number, minor = false) => {
  const [wholeAmount, remainderAmount] = splitAmount(amountMinorUnits, minor);
  const wholePart = numberWithDots(wholeAmount);
  const remainderPart =
    remainderAmount > 0 ? `,${remainderAmount.toString().slice(2)}` : "";

  return `${wholePart}${remainderPart}`;
};

export const formatAmount = (
  amountMinorUnits: number,
  currencyCode: string,
  minor = false
) => {
  const amount = getAmount(amountMinorUnits, minor);

  return `${amount} ${currencyCode}`;
};
