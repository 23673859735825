import { PaymentType } from "../pages/types";

export const routePathByPage = {
  list: `/:lang/payments/:paymentType(${PaymentType.Successful}|${PaymentType.Internal}|${PaymentType.Unidentified})`,
  details: `/:lang/payments/:paymentType(${PaymentType.Successful}|${PaymentType.Internal}|${PaymentType.Unidentified})/:id`,
  successfulView: `/:lang/payments/:paymentType(${PaymentType.Successful})/:id`,
  internalView: `/:lang/payments/:paymentType(${PaymentType.Internal})/:id`,
  unidentifiedView: `/:lang/payments/:paymentType(${PaymentType.Unidentified})/:id`,
  unidentifiedEdit: `/:lang/payments/:paymentType(${PaymentType.Unidentified})/:id/edit`,
} as const;

export type RoutePathByPage = typeof routePathByPage;
export type PathName = keyof RoutePathByPage;
export type Path = RoutePathByPage[PathName];
