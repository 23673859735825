import { ComponentType } from "react";

import {
  Permission,
  UnidentifiedEditPermission,
  ViewPermission,
  routePathByPage,
} from "./constants";
import PaymentsOverview from "./pages/PaymentsOverview";
import { SuccessfulPaymentView } from "./pages/SuccessfulPaymentView";
import { InternalPaymentView } from "./pages/InternalPaymentView";
import { UnidentifiedPaymentView } from "./pages/UnidentifiedPaymentView";
import { UnidentifiedPaymentEdit } from "./pages/UnidentifiedPaymentEdit";

interface Route {
  path: string;
  exact: boolean;
  component: ComponentType;
  permissions: Permission[];
}

const routes: Array<Route> = [
  {
    path: routePathByPage.list,
    exact: true,
    permissions: [
      ViewPermission.queryOtherPayments,
      ViewPermission.queryRetailPayments,
      ViewPermission.queryUnidentifiedPayments,
    ],
    component: PaymentsOverview,
  },
  {
    path: routePathByPage.successfulView,
    exact: true,
    permissions: [ViewPermission.queryRetailPayment],
    component: SuccessfulPaymentView,
  },
  {
    path: routePathByPage.internalView,
    exact: true,
    permissions: [ViewPermission.queryOtherPayment],
    component: InternalPaymentView,
  },
  {
    path: routePathByPage.unidentifiedView,
    exact: true,
    permissions: [ViewPermission.queryUnidentifiedPayment],
    component: UnidentifiedPaymentView,
  },
  {
    path: routePathByPage.unidentifiedEdit,
    exact: true,
    permissions: Object.values(UnidentifiedEditPermission),
    component: UnidentifiedPaymentEdit,
  },
];

export default routes;
