import { useMemo, useContext } from "react";

import { Permission } from "../constants";
import { PermissionsContext } from "../providers/PermissionsProvider";

export const useCheckPermissions = (permissions: Permission | Permission[]) => {
  const { permissions: userPermissions } = useContext(PermissionsContext);

  return useMemo(
    () =>
      [permissions]
        .flat()
        .every((permission: Permission) =>
          userPermissions.includes(permission)
        ),
    [permissions, userPermissions]
  );
};
