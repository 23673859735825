import { Button, Space } from "antd";

import { PaymentOverviewEntity } from "./PaymentsOverview/hooks";
import { usePaymentDetailsNavigation } from "./usePaymentDetailsNavigation";

export const PaymentDetailsNavigation = ({
  onNavigate,
  onSearchMoreClick,
}: {
  onSearchMoreClick: () => void;
  onNavigate: (payment: PaymentOverviewEntity, page: number) => void;
}) => {
  const { hasPrev, goToPrev, hasNext, goToNext } =
    usePaymentDetailsNavigation(onNavigate);
  return (
    <Space>
      <Button disabled={!hasPrev} onClick={goToPrev}>
        Prev
      </Button>
      <Button disabled={!hasNext} onClick={goToNext}>
        Next
      </Button>
      <Button onClick={onSearchMoreClick}>Search more</Button>
    </Space>
  );
};
