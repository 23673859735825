import { PaymentType } from "../../../pages/types";
import { Domain } from "../../../constants";

import {
  otherPaymentsQuery,
  unidentifiedPaymentsQuery,
  successfulPaymentsQuery,
} from "./queries";

type Config = {
  query: string;
  domain: Domain;
};

export const configByPaymentType: Record<PaymentType, Config> = {
  successful: {
    query: successfulPaymentsQuery,
    domain: Domain.ORDER,
  },
  unidentified: {
    query: unidentifiedPaymentsQuery,
    domain: Domain.PAYMENTS,
  },
  internal: {
    query: otherPaymentsQuery,
    domain: Domain.PAYMENTS,
  },
};
