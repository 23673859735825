import { Route, Switch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useEffect } from "react";

import { useHasPermissions } from "./hooks";
import routes from "./routes";
import { ERROR_MESSAGE } from "./constants";
import useError from "./hooks/useError";

export const Routing = () => {
  const hasPermissions = useHasPermissions();

  const client = useQueryClient();
  const { addError } = useError();

  useEffect(() => {
    client.setDefaultOptions({
      queries: {
        onError: (err) =>
          addError((typeof err === "string" && err) || ERROR_MESSAGE),
      },
      mutations: {
        onError: (err) =>
          addError((typeof err === "string" && err) || ERROR_MESSAGE),
      },
    });
  }, [addError, client]);

  return (
    <Switch>
      {routes
        .filter(({ permissions }) => hasPermissions(permissions))
        .map(({ path, exact, component }) => (
          <Route key={path} component={component} path={path} exact={exact} />
        ))}
    </Switch>
  );
};
