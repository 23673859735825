import { GraphQLError } from "graphql/error";

import { HttpMethods } from "./types";

function getCookieByName(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
}

interface ApiCallOptions {
  method: HttpMethods;
  url: string;
  domain: string;
  body?: object;
}

// TODO
export const callApi = async <DataT = any>({
  method,
  url,
  domain,
  body,
}: ApiCallOptions) => {
  const accessToken = getCookieByName("t");

  const headers = {
    "access-domain": domain,
    accept: "application/json, text/plain, */*",
    "content-type": "application/json;charset=UTF-8",
    authorization: `Bearer ${accessToken}`,
  };

  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  });

  return response.json() as {
    data?: DataT;
    errors?: Array<
      GraphQLError & {
        exception?: {
          localizedMessage: unknown;
        };
      }
    >;
  };
};

const gqlServiceUrl = "/api/v1/retail-graphql-gateway";

interface GqlCallOptions {
  query: string;
  variables?: object;
  domain?: string;
}

export const callGql = <DataT = any>({
  query = "",
  variables = {},
  domain = "",
}: GqlCallOptions) =>
  callApi<DataT>({
    method: HttpMethods.POST,
    url: gqlServiceUrl,
    domain,
    body: { query, variables },
  });
