export enum Domain {
  ORDER = "order.management",
  PAYMENTS = "payments.import.management",
}

export enum ViewPermission {
  queryRetailPayment = "gqls://order.management/query/retailpayment",
  queryRetailPayments = "gqls://order.management/query/searchretailpayments",
  queryOtherPayment = "gqls://payments.import.management/query/internaltransferpaymentbyid",
  queryOtherPayments = "gqls://payments.import.management/query/searchinternaltransferpayments",
  queryUnidentifiedPayment = "gqls://payments.import.management/query/unidentifiedpaymentbyid",
  queryUnidentifiedPayments = "gqls://payments.import.management/query/searchretailunidentifiedpayments",
}

export enum SuccessfulEditPermission {
  updateSuccessfulPayment = "gqls://payments.import.management/mutation/updateretailpayment",
}

export enum InternalEditPermission {
  markInternalAsUnidentifiedPayment = "gqls://payments.import.management/mutation/transitionpaymentinternaltransfertounidentified",
}

export enum UnidentifiedEditPermission {
  assignUnidentifiedPaymentToOrder = "gqls://payments.import.management/mutation/assignunidentifiedpaymenttoorder",
  markUnidentifiedPaymentAsOther = "gqls://payments.import.management/mutation/transitionpaymentunidentifiedtointernaltransfer",
  updateUnidentifiedPayment = "gqls://payments.import.management/mutation/updateretailunidentifiedpayment",
  changeRetailPaymentOrder = "gqls://payments.import.management/mutation/changeretailpaymentorder",
  transitionPaymentUnidentifiedToInternalTransferBatch = "gqls://payments.import.management/mutation/transitionpaymentunidentifiedtointernaltransferbatch",
  distributeUnidentifiedPayment = "gqls://payments.import.management/mutation/distributeunidentifiedpayment",
}

export const Permission = {
  ...ViewPermission,
  ...SuccessfulEditPermission,
  ...InternalEditPermission,
  ...UnidentifiedEditPermission,
};
export type Permission =
  | ViewPermission
  | SuccessfulEditPermission
  | InternalEditPermission
  | UnidentifiedEditPermission;
