import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { searchSelectors, UnidentifiedEditPermission } from "../../constants";
import { UnidentifiedPayment } from "../../adapters";
import { useHasPermissions } from "../../hooks";

import { DistributePaymentButton } from "./DistributePaymentButton";

const { Item } = Form;

export type OrderSearchFormVM = {
  orderId?: string;
};

interface OrderMatchesSearchBarProps {
  onSearch: (values: OrderSearchFormVM) => void;
  payment: UnidentifiedPayment | undefined;
}

const OrderMatchesSearchBar = ({
  onSearch,
  payment,
}: OrderMatchesSearchBarProps) => {
  const [formValues, setFormValues] = useState<OrderSearchFormVM>(() => ({
    orderId: "",
  }));
  const hasPermission = useHasPermissions();

  return (
    <Form
      onFinish={() => onSearch(formValues)}
      layout="inline"
      style={{ marginBottom: "10px" }}
    >
      <Item>
        <Input
          value={formValues.orderId}
          placeholder="Order number"
          prefix={<SearchOutlined />}
          onChange={(e) => setFormValues({ orderId: e.target.value })}
          data-qa-selector={searchSelectors.orderIdInput}
        />
      </Item>
      <Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={!formValues.orderId}
          icon={<SearchOutlined />}
          data-qa-selector={searchSelectors.searchBtn}
        />
      </Item>
      {hasPermission(
        UnidentifiedEditPermission.distributeUnidentifiedPayment
      ) ? (
        <Item>
          <DistributePaymentButton payment={payment} />
        </Item>
      ) : null}
    </Form>
  );
};

export default OrderMatchesSearchBar;
