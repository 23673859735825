export const successfulPaymentsQuery = `query($search: SearchProjection2Input!) {
  searchRetailPayments(search: $search) {
    totalEntityCount
    entities {
      paymentDate
      accountDetails {
        holder
        bic
        iban
      }
      accountingDate
      amount {
        amountMinorUnits
        currencyCode
      }
      countryCode
      createdOn
      externalId
      id
      orderId
      type
      importDetails {
        transactionCode
        recipientBankAccount
      }
    }
  }
}`;
