import { Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { useMemo, Key } from "react";

import { PaymentOverviewEntity } from "../hooks";
import { paymentsOverviewSelectors } from "../../../constants";
import { useCurrentPaymentType } from "../../../pages/hooks";

import { getPaymentsOverviewColumns } from "./getPaymentsOverviewColumns";
import { PaymentActionsColumn } from "./PaymentActionsColumn";

interface PaymentsOverviewTableProps {
  data: Array<PaymentOverviewEntity>;
  page: number | string;
  loading: boolean;
  setSelectedRowKeys: (keys: Key[]) => void;
}

const PaymentsOverviewTable = ({
  loading,
  data,
  page,
  setSelectedRowKeys,
}: PaymentsOverviewTableProps) => {
  const paymentType = useCurrentPaymentType();
  const columns = useMemo(() => {
    const actionColumn: ColumnType<PaymentOverviewEntity> = {
      dataIndex: "action",
      key: "action",
      title: "Action",
      render: (_, { id, externalId, orderId }) => (
        <PaymentActionsColumn
          qaSelector={paymentsOverviewSelectors.action}
          page={page}
          paymentId={id}
          externalId={externalId}
          orderId={orderId}
        />
      ),
    };

    return [...getPaymentsOverviewColumns(paymentType), actionColumn];
  }, [page, paymentType]);

  const rowSelection = {
    onChange: (selectedRowKeys: Key[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };
  return (
    <Table
      loading={loading}
      bordered
      dataSource={data}
      pagination={false}
      size="small"
      rowKey="id"
      scroll={{ x: true }}
      style={{ marginBottom: "5px" }}
      columns={columns}
      rowSelection={rowSelection}
    />
  );
};

export default PaymentsOverviewTable;
