import { PaymentDistribution } from "../parsePaymentDistributionInput";

import { isCategory, validateCategories } from "./validateCategories";
import { validateAmounts } from "./validateAmounts";
import { validateOrderNumbers } from "./validateOrderNumbers";

export const validatePaymentDistribution = (
  paymentDistributions: PaymentDistribution[],
  existingOrderNumbers: string[]
) => {
  const parsedCategories = paymentDistributions
    .map((row) => row.orderNumberOrCategory ?? "")
    .filter(isCategory);
  const categoriesErrorMessage = validateCategories(parsedCategories);

  const parsedAmounts = paymentDistributions.map((row) => row.amountMinorUnits);
  const amountErrorMessage = validateAmounts(parsedAmounts);

  const parsedOrderNumbers = paymentDistributions
    .map((row) => row.orderNumberOrCategory ?? "")
    .filter((orderNumberOrCategory) => !isCategory(orderNumberOrCategory));
  const orderNumbersError = validateOrderNumbers(
    existingOrderNumbers,
    parsedOrderNumbers
  );

  return {
    orderNumbers: orderNumbersError ?? "",
    category: categoriesErrorMessage ?? "",
    amount: amountErrorMessage ?? "",
    unknownFields: paymentDistributions.some((d) => d.unknownFields?.length)
      ? "Only two values are acceptable as Payment distribution parameters. Please check your input data"
      : "",
  };
};
