import { useMutation, useQueryClient } from "react-query";
import { message } from "antd";

import { useGoBackToOverviewPage } from "./../../useGoBackToOverviewPage";
import { assignUnidentifiedPaymentToOrder } from "./assignUnidentifiedPaymentToOrder";

export const useAssignUnidentifiedPaymentToOrder = () => {
  const goBackToOverviewPage = useGoBackToOverviewPage();
  const queryClient = useQueryClient();
  return useMutation(
    async ({ paymentId, orderId }: { paymentId: string; orderId: string }) => {
      await assignUnidentifiedPaymentToOrder(paymentId, orderId);
      goBackToOverviewPage();
      queryClient.invalidateQueries(["payments"]);

      message.success("Success");
    }
  );
};
