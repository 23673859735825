import { useCallback } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { PaymentType } from "../../../pages/types";
import { useQueryParams } from "../../../hooks";

import { fetchPaymentDetails } from "./fetchPaymentDetails";

export const usePaymentDetails = <T extends PaymentType = PaymentType>(
  paymentType: T
) => {
  const { id: paymentId } = useParams<{ id: string }>();
  const [{ orderId }] = useQueryParams();

  const fetch = useCallback(async () => {
    if (paymentId) {
      return fetchPaymentDetails(paymentType, paymentId, orderId);
    }
  }, [paymentType, paymentId, orderId]);

  return useQuery(["paymentDetails", paymentId, orderId], fetch);
};
