import { getAmount } from "../../../../utils/amount";
import { FetchUnidentifiedPaymentsResponse } from "../../fetchPayments";
import { PaymentCsvColumn } from "../types";
import { formatDate, formatPaymentMethod } from "../../../../utils";

export const unidentifiedPaymentsResponseToCsvRowsAdapter = (
  response: FetchUnidentifiedPaymentsResponse
) =>
  response.searchRetailUnidentifiedPayments.entities.map((entity) => ({
    [PaymentCsvColumn.BankAccount]: entity.paymentData.bankAccount,
    [PaymentCsvColumn.AccountingDate]: formatDate(entity.accountingDate),
    [PaymentCsvColumn.PaymentDate]: formatDate(entity.paymentData.paymentDate),
    [PaymentCsvColumn.BankReferenceNumber]:
      entity.paymentData.bankReferenceNumber,
    [PaymentCsvColumn.EndToEndReference]: entity.paymentData.endToEndReference,
    [PaymentCsvColumn.PaymentMethod]: formatPaymentMethod(
      entity.paymentData.paymentType
    ),
    [PaymentCsvColumn.Gvc]: "-",
    [PaymentCsvColumn.Amount]: getAmount(entity.paymentData.amount, true),
    [PaymentCsvColumn.CurrencyCode]:
      entity.paymentData.currencyCode.description,
    [PaymentCsvColumn.AccountHolderName]: entity.paymentData.accountHolder,
    [PaymentCsvColumn.CustomerPaymentReference]:
      entity.paymentData.customerPaymentReference,
    [PaymentCsvColumn.Bic]: entity.paymentData.bic,
    [PaymentCsvColumn.Iban]: entity.paymentData.iban,
    id: entity.id,
  }));
