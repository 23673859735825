import {
  UnidentifiedPayment,
  SuccessfulPayment,
  OtherPayment,
} from "../adapters";

export enum PaymentType {
  Successful = "successful",
  Internal = "internal",
  Unidentified = "unidentified",
}

export type Payment = UnidentifiedPayment | SuccessfulPayment | OtherPayment;

export type PaymentMethod =
  | "WIRE_TRANSFER_CUSTOMER"
  | "WIRE_TRANSFER_BANK_FINANCE"
  | "ONLINE_CREDIT_CARD"
  | "ONLINE_BANK_TRANSFER"
  | "ONLINE_PAYPAL"
  | "MANUALLY_CREATED"
  | "TRADE_IN"
  | "ONLINE_DEBIT_CARD";
