import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { message } from "antd";

import { callGql } from "../../../utils/apiClient";
import { Domain } from "../../../constants";
import { useCurrentPaymentType, usePaymentDetails } from "../../../pages/hooks";

import { changeRetailPaymentOrder } from "./changeRetailPaymentOrder";

export const useChangeRetailPaymentOrder = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const paymentType = useCurrentPaymentType();
  const { refetch } = usePaymentDetails(paymentType);
  return useMutation({
    onSuccess,
    mutationFn: async (orderNumber: string) => {
      const { data, errors } = await callGql({
        query: changeRetailPaymentOrder,
        variables: {
          id,
          orderNumber,
        },
        domain: Domain.PAYMENTS,
      });
      if (errors?.length) {
        throw errors[0]?.message;
      }
      await refetch();
      message.success("Order changed successfully");
      return data;
    },
  });
};
