export enum paymentsOverviewSelectors {
  uploadedOn = "cell-uploadedOn",
  accountingDate = "cell-accountingDate",
  paymentMethod = "cell-paymentMethod",
  externalId = "cell-externalId",
  uploadStatus = "cell-upload-status",
  status = "cell-status",
  amount = "cell-amount",
  accountHolderName = "cell-accountHolderName",
  orderId = "cell-orderId",
  country = "cell-country",
  action = "cell-action",
  viewButton = "viewButton",
  editButton = "editButton",
}

export enum paymentOverviewSelectors {
  createdOn = "cell-viewCreatedOn",
  accountingDate = "cell-viewAccountingDate",
  source = "cell-viewCreationSource",
  recipientBank = "cell-viewRecipientBankAccount",
  paymentType = "cell-viewPaymentType",
  uploadStatus = "cell-viewPaymentStatus",
  category = "cell-viewPaymentCategory",
  errorMessage = "cell-viewErrorMessage",
  amount = "cell-viewAmount",
  accountHolderName = "cell-viewAccountHolderName",
  customerName = "cell-viewCustomerName",
  bic = "cell-viewBic",
  iban = "cell-viewIban",
  customerReference = "cell-viewCustomerPaymentReference",
  orderId = "cell-viewOrderId",
  stockId = "cell-viewStockId",
  retailCountry = "cell-viewRetailCountry",
  attachConfirmBtn = "attachConfirmButton",
  attachCancelBtn = "attachCancelButton",
  markAsInternalBtn = "markInternalButton",
  putOnHoldButton = "putOnHoldButton",
  markAsInternalConfirmBtn = "internalPaymentConfirmButton",
  markAsInternalCancelBtn = "internalPaymentCancelButton",
}

export enum suggestionsSelectors {
  orderId = "cell-suggestionsOrderId",
  stockId = "cell-suggestionsStockNumber",
  customerName = "cell-suggestionsCustomerName",
  amount = "cell-suggestionsTotalAmount",
  remainingAmount = "cell-suggestionsRemainingAmount",
  orderStatus = "cell-suggestionsOrderStatus",
  country = "cell-suggestionsCountry",
  action = "cell-suggestionsAction",
  attachBtn = "suggestionsAttachButton",
}

export enum searchSelectors {
  orderIdInput = "input-searchByOrderId",
  invoiceIdInput = "input-searchByInvoiceId",
  nameInput = "input-searchByName",
  lastNameInput = "input-searchByLastName",
  searchBtn = "searchOrderButton",
  orderId = "cell-searchOrderId",
  stockId = "cell-searchStockNumber",
  customerName = "cell-searchCustomerName",
  amount = "cell-searchTotalAmount",
  remainingAmount = "cell-searchRemainingAmount",
  orderStatus = "cell-searchOrderStatus",
  country = "cell-searchCountry",
  action = "cell-searchAction",
  attachBtn = "searchAttachButton",
}
