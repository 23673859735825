import { formatAmount } from "../utils/amount";
import { formatDate } from "../utils/date";
import { PaymentUploadStatus } from "../constants";

import { PaymentBase } from "./types";
import { formatSource } from "./formatSource";

export type UnidentifiedPayment = PaymentBase & {
  orderNumbers: string[];
  currencyCode: string;
  amountMinorUnits: number;
  uploadStatus: PaymentUploadStatus;
};

// TODO
export const unidentifiedPaymentAdapter = ({
  unidentifiedPaymentById: data,
}: any): UnidentifiedPayment => ({
  id: data.id,
  createdOn: formatDate(data.uploadDate, true),
  accountingDate: formatDate(data.accountingDate),
  source: formatSource({
    creationSource: data.creationSource,
    hasExternalPaymentSystem: !!data.externalPaymentSystem,
  }),
  recipientBank: data.paymentData.bankAccount,
  paymentType: data.paymentData.paymentType,
  uploadStatus: PaymentUploadStatus.UNIDENTIFIED,
  status: data.status,
  errorMessage: data.errorMessage,
  amount: formatAmount(
    data.paymentData.amount,
    data.paymentData.currencyCode.description,
    true
  ),
  amountMinorUnits: data.paymentData.amount,
  currencyCode: data.paymentData.currencyCode.description,
  accountHolderName: data.paymentData.accountHolder,
  bic: data.paymentData.bic,
  iban: data.paymentData.iban,
  customerReference: data.paymentData.customerPaymentReference,
  retailCountry: data.paymentData.countryCode,
  externalId: data.externalId,
  orderNumbers: data.paymentData?.orderNumbers,
});
