import { Button, Form, Space } from "antd";

import { routePathByPage, SuccessfulEditPermission } from "../../constants";
import { useHasPermissions } from "../../hooks";
import { PaymentOverviewCard } from "../../components";
import { PaymentField } from "../../components/PaymentOverviewCard";
import { SuccessfulPayment } from "../../adapters";
import { PaymentHeader } from "../PaymentHeader";
import { usePaymentDetails } from "../hooks";
import { PaymentType, PaymentMethod } from "../types";

import { useUpdateSuccessfulPayment } from "./useUpdateSuccessfulPayment";

const canUpdateSuccessfulPaymentMethod = (method?: PaymentMethod) =>
  method === "WIRE_TRANSFER_CUSTOMER" ||
  method === "WIRE_TRANSFER_BANK_FINANCE";

export const SuccessfulPaymentView = () => {
  const { data: payment } = usePaymentDetails(PaymentType.Successful);
  const hasPermissions = useHasPermissions();
  const canUpdateSuccessfulPayment = hasPermissions(
    SuccessfulEditPermission.updateSuccessfulPayment
  );

  const [form] = Form.useForm<SuccessfulPayment>();
  const updatePaymentMutation = useUpdateSuccessfulPayment(payment?.id);
  const editableFields = [
    canUpdateSuccessfulPaymentMethod(payment?.paymentType)
      ? "paymentType"
      : null,
  ].filter(Boolean) as PaymentField[];

  return (
    <>
      <PaymentHeader
        payment={payment}
        navigationPath={routePathByPage.successfulView}
      />
      {canUpdateSuccessfulPayment && payment ? (
        <Form
          form={form}
          initialValues={payment}
          onFinish={updatePaymentMutation.mutate}
        >
          <PaymentOverviewCard
            mode="edit"
            editableFields={editableFields}
            payment={payment}
          />
          <Space
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              disabled={updatePaymentMutation.isLoading}
              loading={updatePaymentMutation.isLoading}
            >
              Save
            </Button>
          </Space>
        </Form>
      ) : (
        <PaymentOverviewCard mode="view" payment={payment} />
      )}
    </>
  );
};
