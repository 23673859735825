import { Key, useState } from "react";
import { Button, Modal } from "antd";

import { saveAsCsv } from "./saveAsCsv";
import { CsvRow } from "./types";

type ExportToCsvButtonProps = {
  total: number;
  getCsvRows: () => Promise<CsvRow[]>;
  getFileName: () => string;
  maxItemsToExport: number;
  selectedRowKeys: Key[];
};

export const ExportToCsvButton = ({
  getFileName,
  total,
  maxItemsToExport,
  selectedRowKeys,
  getCsvRows,
}: ExportToCsvButtonProps) => {
  const [opened, setOpened] = useState(false);
  const [warnOpened, setWarnOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let rows = await getCsvRows();
      if (selectedRowKeys.length !== 0) {
        rows = rows.filter(({ id }) => selectedRowKeys.includes(id));
      }
      if (rows) {
        await saveAsCsv(
          rows.map(({ id, ...row }) => row),
          { filename: getFileName() }
        );
      }
      setOpened(false);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => setOpened(false);

  return (
    <>
      <Button
        disabled={loading || !total}
        onClick={() =>
          selectedRowKeys.length || total <= maxItemsToExport
            ? setOpened(true)
            : setWarnOpened(true)
        }
      >
        Export Payments
        {selectedRowKeys.length ? <> ({selectedRowKeys.length})</> : null}
      </Button>
      <Modal
        title="Warning"
        visible={warnOpened}
        onCancel={() => setWarnOpened(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelText="Close"
      >
        Maximum number of records that can be exported is not more than{" "}
        {maxItemsToExport} rows at a time. Please adjust your filters to export
        the data and try again.
      </Modal>
      <Modal
        title="Confirm"
        visible={opened}
        onOk={handleSubmit}
        onCancel={handleCancel}
        okButtonProps={{ loading }}
      >
        Export {selectedRowKeys?.length || total} payment rows?
      </Modal>
    </>
  );
};
