export const unidentifiedPaymentsQuery = `query($search: SearchProjection2Input!) {
  searchRetailUnidentifiedPayments(search: $search) {
    totalEntityCount
    entities {
      externalId
      id
      accountingDate
      status
      paymentData {
        bankAccount
        paymentDate
        bankReferenceNumber
        endToEndReference
        customerPaymentReference
        bic
        iban
        accountHolder
        amount
        countryCode
        currencyCode {
          description
          country
        }
        paymentType
      }
      uploadDate
    }
  }
}`;
