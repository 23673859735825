import { paymentsOverviewSelectors } from "../../../constants";
import { PaymentType } from "../../types";

import {
  renderCell,
  renderOrderId,
  renderStatus,
  renderUploadStatus,
} from "./renderers";

interface Column {
  dataIndex: string;
  key: string;
  title: string;
  render: ReturnType<typeof renderCell>;
}

export const getPaymentsOverviewColumns = (paymentType: PaymentType) =>
  [
    {
      dataIndex: "uploadTimestamp",
      key: "uploadedOn",
      title: "Upload Timestamp",
      render: renderCell(paymentsOverviewSelectors.uploadedOn),
    },
    paymentType === PaymentType.Internal
      ? null
      : {
          dataIndex: "uploadStatus",
          key: "uploadStatus",
          title: "Upload Status",
          render: renderUploadStatus(paymentsOverviewSelectors.uploadStatus),
        },
    {
      dataIndex: "accountingDate",
      key: "accountingDate",
      title: "Accounting Date",
      render: renderCell(paymentsOverviewSelectors.accountingDate),
    },
    {
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      title: "Payment Method",
      render: renderCell(paymentsOverviewSelectors.paymentMethod),
    },
    {
      dataIndex: "externalId",
      key: "externalId",
      title: "External ID",
      render: renderCell(paymentsOverviewSelectors.externalId),
    },
    paymentType === PaymentType.Unidentified
      ? {
          dataIndex: "status",
          key: "status",
          title: "Status",
          render: renderStatus(paymentsOverviewSelectors.status),
        }
      : null,
    {
      dataIndex: "amount",
      key: "amount",
      title: "Amount (Currency)",
      render: renderCell(paymentsOverviewSelectors.amount),
    },
    {
      dataIndex: "accountHolder",
      key: "accountHolderName",
      title: "Account Holder Name",
      render: renderCell(paymentsOverviewSelectors.accountHolderName),
    },
    paymentType === PaymentType.Successful
      ? {
          dataIndex: "orderNumber",
          key: "orderId",
          title: "Order Number",
          render: renderOrderId(paymentsOverviewSelectors.orderId),
        }
      : null,
    {
      dataIndex: "country",
      key: "country",
      title: "Country",
      render: renderCell(paymentsOverviewSelectors.country),
    },
  ].filter<Column>(Boolean);
