import { useMemo, useCallback } from "react";
import { format, isValid, parse } from "date-fns";

import { useQueryParams } from "../../../hooks";
import { dateFormat } from "../../../utils/date";
import { useCurrentPaymentType } from "../../hooks";
import { PaymentType } from "../../../pages/types";

import { OtherPaymentType } from "./../../../adapters/otherPaymentAdapter";
import { QueryParams } from "./../../../hooks/useQueryParams";

const formatDate = (date: Date | undefined) =>
  date && isValid(date) ? format(date, dateFormat) : undefined;
const parseDate = (date: string | undefined) =>
  date ? parse(date, dateFormat, new Date()) : undefined;

export type InboundsFilter = {
  country?: string;
  creationSource?: string;
  status?: string;
  externalId?: string;
  orderNumber?: string;
  category?: OtherPaymentType;
  paymentMethod?: string;
  accountingDate?: readonly [Date | undefined, Date | undefined];
  uploadDate?: readonly [Date | undefined, Date | undefined];
  paymentType?: PaymentType;
  accountHolderName?: string;
  iban?: string;
  amount?: string;
};

export const adaptInboundsFilterToQueryParams = ({
  accountingDate,
  uploadDate,
  paymentType,
  ...rest
}: InboundsFilter): QueryParams => ({
  ...rest,
  paymentType,
  accountingDateFrom: formatDate(accountingDate?.[0]),
  accountingDateTo: formatDate(accountingDate?.[1]),
  uploadDateFrom: formatDate(uploadDate?.[0]),
  uploadDateTo: formatDate(uploadDate?.[1]),
});

export const useInboundsFilter = () => {
  const [search, setSearch] = useQueryParams();
  const currentPaymentType = useCurrentPaymentType();
  const paymentType =
    (search["paymentType"] as PaymentType) ?? currentPaymentType;
  const country = search["country"];
  const iban = search["iban"];
  const creationSource = search["creationSource"];
  const accountHolderName = search["accountHolderName"];
  const amount = search["amount"];
  const status = search["status"];
  const externalId = search["externalId"];
  const orderNumber = search["orderNumber"];
  const paymentMethod = search["paymentMethod"];
  const accountingDateFrom = search["accountingDateFrom"];
  const accountingDateTo = search["accountingDateTo"];
  const uploadDateFrom = search["uploadDateFrom"];
  const uploadDateTo = search["uploadDateTo"];
  const category = search["category"] as OtherPaymentType | undefined;

  const filter: InboundsFilter = useMemo(
    () => ({
      country,
      status,
      externalId,
      orderNumber,
      category,
      creationSource,
      paymentMethod,
      iban,
      accountHolderName,
      amount,
      accountingDate: [
        parseDate(accountingDateFrom),
        parseDate(accountingDateTo),
      ],
      uploadDate: [parseDate(uploadDateFrom), parseDate(uploadDateTo)],
      paymentType,
    }),
    [
      country,
      status,
      externalId,
      orderNumber,
      category,
      creationSource,
      paymentMethod,
      iban,
      accountHolderName,
      amount,
      accountingDateFrom,
      accountingDateTo,
      uploadDateFrom,
      uploadDateTo,
      paymentType,
    ]
  );

  const setFilter = useCallback(
    (vals: Omit<InboundsFilter, "paymentType">) =>
      setSearch(adaptInboundsFilterToQueryParams({ ...vals, paymentType })),
    [paymentType, setSearch]
  );

  return [filter, setFilter] as const;
};
