import { labelByOtherPaymentType } from "../../../../constants";

const availableCategories = Object.values(labelByOtherPaymentType).map((v) =>
  v.toLowerCase()
);
export const isCategory = (category: string) => {
  return availableCategories.includes(category.toLowerCase());
};

export const validateCategories = (categoriesToCheck: string[]) => {
  const unknownCategories = categoriesToCheck.filter(
    (pastedCategory) => !isCategory(pastedCategory)
  );

  if (unknownCategories.length) {
    const categoriesErrorMessage = `Categor${
      unknownCategories.length > 1 ? "ies" : "y"
    } "${unknownCategories.join('", "')}" ${
      unknownCategories.length > 1 ? "do" : "does"
    } not exist`;

    return categoriesErrorMessage;
  }
};
