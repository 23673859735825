import { suggestionsSelectors, searchSelectors } from "../../constants";

import { renderCell } from "./OrderMatchesActionsColumn";

export interface Column {
  dataIndex: string;
  key: string;
  title: string;
  render?: ReturnType<typeof renderCell>;
}

export const SUGGESTION_COLUMNS: Array<Column> = [
  {
    dataIndex: "orderId",
    key: "orderId",
    title: "Order Number",
    render: renderCell(suggestionsSelectors.orderId),
  },
  {
    dataIndex: "stockId",
    key: "stockId",
    title: "Stock number",
    render: renderCell(suggestionsSelectors.stockId),
  },
  {
    dataIndex: "customerName",
    key: "customerName",
    title: "Customer name",
    render: renderCell(suggestionsSelectors.customerName),
  },
  {
    dataIndex: "amount",
    key: "amount",
    title: "Total order amount (currency)",
    render: renderCell(suggestionsSelectors.amount),
  },
  {
    dataIndex: "remainingAmount",
    key: "remainingAmount",
    title: "Remaining amount to be paid (currency)",
    render: renderCell(suggestionsSelectors.remainingAmount),
  },
  {
    dataIndex: "orderStatus",
    key: "orderStatus",
    title: "Order status",
    render: renderCell(suggestionsSelectors.orderStatus),
  },
  {
    dataIndex: "country",
    key: "country",
    title: "Country",
    render: renderCell(suggestionsSelectors.country),
  },
];

export const SEARCH_COLUMNS: Array<Column> = [
  {
    dataIndex: "orderId",
    key: "orderId",
    title: "Order Number",
    render: renderCell(searchSelectors.orderId),
  },
  {
    dataIndex: "stockId",
    key: "stockId",
    title: "Stock number",
    render: renderCell(searchSelectors.stockId),
  },
  {
    dataIndex: "customerName",
    key: "customerName",
    title: "Customer name",
    render: renderCell(searchSelectors.customerName),
  },
  {
    dataIndex: "amount",
    key: "amount",
    title: "Total order amount (currency)",
    render: renderCell(searchSelectors.amount),
  },
  {
    dataIndex: "remainingAmount",
    key: "remainingAmount",
    title: "Remaining amount to be payed (currency)",
    render: renderCell(searchSelectors.remainingAmount),
  },
  {
    dataIndex: "orderStatus",
    key: "orderStatus",
    title: "Order status",
    render: renderCell(searchSelectors.orderStatus),
  },
  {
    dataIndex: "country",
    key: "country",
    title: "Country",
    render: renderCell(searchSelectors.country),
  },
];
