export interface SearchProps {
  orderNumbers?: Array<string>;
}

interface FilterItem {
  field: string;
  op: FilterOperation;
  value: string;
}

interface Filter {
  field: string | null;
  op: FilterOperation;
  value: string | Array<FilterItem | Filter>;
}

enum FilterOperation {
  and = "and",
  eq = "eq",
  notEq = "ne",
  or = "or",
}

const buildFiltersList = (
  props: Omit<SearchProps, "type">
): Array<FilterItem> =>
  (props.orderNumbers ?? [])
    .map((orderNumber) => ({
      field: "orderNumber",
      op: FilterOperation.eq,
      value: orderNumber,
    }))
    .filter(Boolean) as FilterItem[];

export const buildSearchOrdersFilter = (
  searchProps: SearchProps
): Filter | null => {
  const filtersList = buildFiltersList(searchProps);
  if (!filtersList.length) return null;
  if (filtersList.length === 1) return filtersList[0];

  return {
    field: null,
    op: FilterOperation.or,
    value: filtersList,
  } as Filter;
};
