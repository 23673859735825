import { Table } from "antd";

import { PaymentMethod } from "../../../pages/types";

type AttributionsTableRow = {
  accountingDate: string | undefined;
  paymentMethod: PaymentMethod | undefined;
  externalId: string;
  amount: string;
  orderNumberOrCategory: string;
};

type AttributionsTableProps = {
  data: AttributionsTableRow[];
};

export const AttributionsTable = ({ data }: AttributionsTableProps) => {
  return (
    <Table
      bordered
      dataSource={data}
      pagination={false}
      size="small"
      columns={[
        {
          dataIndex: "accountingDate",
          key: "accountingDate",
          title: "Accounting Date",
        },
        {
          dataIndex: "paymentMethod",
          key: "paymentMethod",
          title: "Payment Method",
        },
        {
          dataIndex: "externalId",
          key: "externalId",
          title: "External ID",
        },
        {
          dataIndex: "amount",
          key: "amount",
          title: "Amount (Currency)",
        },
        {
          dataIndex: "orderNumberOrCategory",
          key: "orderNumberOrCategory",
          title: "Order Number / Category",
        },
      ]}
    />
  );
};
