import React from "react";

import { PaymentUploadStatus } from "../../../constants";
import {
  labelByUnidentifiedPaymentStatusOptions,
  UnidentifiedPaymentStatus,
} from "../PaymentsOverviewFilter";

export const renderCell = (qaSelector: string) => (data: string) =>
  <span data-qa-selector={qaSelector}>{data}</span>;

export const renderOrderId = (qaSelector: string) => (data: string) => {
  const emptySpace = (
    <>
      &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
    </>
  );

  return <span data-qa-selector={qaSelector}>{data ? data : emptySpace}</span>;
};

export const renderUploadStatus =
  (qaSelector: string) => (uploadStatus: string) => {
    const style = {
      color: uploadStatus === PaymentUploadStatus.SUCCESSFUL ? "green" : "red",
    };

    return (
      <span style={style} data-qa-selector={qaSelector}>
        {uploadStatus}
      </span>
    );
  };

export const renderStatus =
  (qaSelector: string) => (status: UnidentifiedPaymentStatus) => {
    const style = {
      color: status === UnidentifiedPaymentStatus.Open ? "green" : "orange",
    };
    const label = labelByUnidentifiedPaymentStatusOptions[status] ?? "";
    return (
      <span style={style} data-qa-selector={qaSelector}>
        {label}
      </span>
    );
  };
