export const otherPaymentQuery = `query($paymentId: UUID!) {
  internalTransferPaymentById(id: $paymentId) {
    id
    importDetails {
      recipientBankAccount
      customerPaymentReference
    }
    accountData {
      bic
      holder
      iban
    }
    category
    accountingDate
    amount {
      amountMinorUnits
      currencyCode
    }
    countryCode
    createdOn
    externalId
    type
    creationSource
  }
}`;
