export enum PaymentCsvColumn {
  BankAccount = "Konto",
  AccountingDate = "Buchungsdatum",
  PaymentDate = "Valutadatum",
  BankReferenceNumber = "Bankreferenz",
  EndToEndReference = "Ende-zu-Ende-Referenz",
  PaymentMethod = "Buchungstext",
  Gvc = "GVC",
  Amount = "Betrag",
  CurrencyCode = "Währung",
  AccountHolderName = "Auftraggeber/Empfänger",
  CustomerPaymentReference = "Verwendungszweck",
  Bic = "BIC",
  Iban = "IBAN",
}
