import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import * as queryString from "query-string";

export type QueryParams = Partial<Record<string, string>>;
const useQueryParams = () => {
  const queryParams = queryString.parse(location.search);
  const history = useHistory();

  const [params, setParams] = useState(queryParams);

  window.onpopstate = function () {
    const queryParams = queryString.parse(location.search);
    setParams(queryParams);
  };

  useEffect(() => {
    setParams(queryString.parse(location.search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const setQueryParams = (queryParams: QueryParams, state: unknown = {}) => {
    const str = queryString.stringify(queryParams);
    const url = location.pathname + (str ? `?${str}` : "");
    history.push(url, state);

    setParams(queryString.parse(str));
  };

  return [params as QueryParams, setQueryParams] as const;
};

export default useQueryParams;
