import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, message, Space } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";

import { PermissionsProvider } from "./providers/PermissionsProvider";
import { ErrorProvider } from "./providers/ErrorProvider";
import { ErrorManager } from "./components";
import { usePageTitle } from "./hooks";
import { Routing } from "./Routing";
import { ANTD_EXTERNAL_VERSION_LESS } from "./constants";

message.config({
  top: 70,
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-message`,
});

const queryClient = new QueryClient();

const Root = () => {
  usePageTitle("Inbound Payments - Overview");

  return (
    <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <PermissionsProvider>
            <ErrorProvider>
              <Space
                direction="vertical"
                size="middle"
                style={{
                  position: "relative",
                  minWidth: "100vw",
                  maxWidth: "100vw",
                  padding: "20px",
                }}
              >
                <Routing />
                <ErrorManager />
              </Space>
            </ErrorProvider>
          </PermissionsProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ConfigProvider>
  );
};

export default Root;
